import * as React from 'react';
import Helmet from 'react-helmet';

const PrivacyPage: React.FC = () => {

  return (
    <>
      <Helmet title="Privacy Policy" />
      <section className="section--privacypolicy">
        <div className="inner inner-padding">
          <h2 className="text-left heading-2-small">Privacy Policy</h2>
          <div className="p-heading--strong font-pn-bold">Security</div>
          <p className="text-left regular">BlueValue&nbsp;respects your privacy and is committed to ensuring that any personal or confidential information that is collected is kept accurate and secure from unauthorized access.</p>
          <div className="p-heading--strong font-pn-bold">Scope</div>
          <p className="text-left regular">This Privacy &amp;&nbsp;Security Statement applies to the BlueValue site and its pages. However, since BlueValue invites a web community presence, it accommodates linked websites, which may espouse different privacy and security statements, BlueValue is not responsible for the privacy and security practices or the content of external linked websites.</p>
          <div className="p-heading--strong font-pn-bold">Cookies</div>
          <p className="text-left regular">A browser cookie file contains unique information that a website can use to track such items as passwords, pages you have visited, the date you last looked at a specific page, and to identify your session at a particular website.</p>
          <p className="text-left regular">We do not use browser cookies to track or collect any information that could personally identify individual visitors. We may, however, use third party analytics services that may use browser cookies to anonymously collect and track site usage information. This information then analyzed as an aggregate, and no personally identifiable information is collected or passed on.</p>
          <p className="text-left regular">If you have any questions, please <a href="/contact/">contact the BlueValue team</a>.</p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPage;
